import React, {Fragment, Component} from 'react';
import M from 'materialize-css';

export default class SideNav extends Component{

    constructor(props){
        super(props);
        this.state = {
            tipo:'',
            correo: '',
            tipoMostrar: '',
            nombre: ''
        }
    }

    componentDidMount = () => {
        var elems = document.querySelectorAll('.sidenav');
        M.Sidenav.init(elems);
    }

    componentDidMount = () => {
        if (sessionStorage.getItem('tipo')) {
            var tipo = sessionStorage.getItem('tipo');
            var corre = sessionStorage.getItem('correo');
            var tipoMostrar = sessionStorage.getItem('tipoMostrar');
            var nombre = sessionStorage.getItem('nombre');
            var desbase64nombre = new Buffer(nombre, 'base64').toString('ascii');
            var desbase64tipo = new Buffer(tipo, 'base64').toString('ascii');
            var desbase64correo = new Buffer(corre, 'base64').toString('ascii');
            var desbase64tipoMostrar = new Buffer(tipoMostrar, 'base64').toString('ascii');
            var elems = document.querySelectorAll('.sidenav');
            M.Sidenav.init(elems);
        } else {
            sessionStorage.error = 'error';
            // window.location.replace('/');
        }
        if (this.state.tipo === '') {
            this.setState({
                tipo: desbase64tipo,
                correo: desbase64correo,
                tipoMostrar: desbase64tipoMostrar,
                nombre: desbase64nombre
            });
        }
    }

    cerrarSesion = () => {
        sessionStorage.clear();
        sessionStorage.error = 'cerrada';
        window.location.replace('/');
    }

    render(){
        return [
            <Fragment>
                    <div>
                        <div style={{backgroundColor: '#EFEFEF', marginBottom:'0px'}}>
                            <div style={{padding:'16px 0', marginLeft:'90%'}}>
                                <a className="col s1" data-target="slide-out" class="sidenav-trigger" href="#!"><i class="material-icons" style={{color:'#707070'}}>menu</i></a>
                            </div>
                        </div>
                        <div id="font" className="center-align" style={{backgroundColor:'#2a79b0 ', color:'#ffffff', marginBottom:'50px'}}>
                            <h2 style={{margin:'0px'}}>{this.props.mensaje}</h2>
                        </div>
                    </div>
                    

                    <ul id="slide-out" class="sidenav">
                        <li>
                            <div class="user-view">
                                <a href="#name"><span class="white-text name">{this.state.tipoMostrar}</span></a>
                                <a href="#name"><span class="white-text name">{this.state.nombre}</span></a>
                                <a href="#email"><span class="white-text email">{this.state.correo}</span></a>
                                <button id="font-button" class="btn waves-effect waves-light" onClick={this.cerrarSesion} name="action" style={{padding:'0 50px 0 50px', background:'#2a79b0 ', marginLeft:'20px'}}>Cerrar Sesion</button>
                            </div>
                        </li>

                        <li><a href="/admin/usuarios"><i class="fas fa-users"></i>Usuarios</a></li>
                        <li><a href="/admin/mensajes"><i class="far fa-envelope"></i>Mensajes</a></li>
                        
                    </ul>
            </Fragment>
        ]
    }

}
