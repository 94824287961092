import React, {Fragment, Component}  from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { firestoreConnect } from 'react-redux-firebase';
import { Table } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import firebaseHelper from '../../../Helpers/Firebase';
import Header from '../../../Components/SideNav';


class Usuarios extends Component {
    
    constructor(props){
        super(props)
        this.state = {
            nombre: ''
        }
    }


    eliminar = (id) => {
        const { firestore } = this.props;
        firebaseHelper.eliminar(id, 'usuario', firestore);
    }


    render(){
        if (!this.props.usuario) {
            return (
                <Fragment>
                    <Header />
                    <div className="container center-align" style={{marginTop:'20%', marginBottom:'20%'}}>
                        <div class="preloader-wrapper big active">
                            <div class="spinner-layer spinner-red darken-4-only">
                                <div class="circle-clipper left">
                                    <div class="circle"></div>
                                </div>
                                <div class="gap-patch">
                                    <div class="circle"></div>
                                </div>
                                <div class="circle-clipper right">
                                    <div class="circle"></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </Fragment>
            );
        } else {
            return (
                <Fragment>
                    <Header mensaje="USUARIOS" />

                    <div className="btn-outline-light container" style={{padding: '30px 0 30px 0'}}>
                        <Link to={'/admin/nuevo-usuario'} id="font" class="btn waves-effect waves-light" style={{padding:'0 50px 0 50px', background:'#2a79b0 '}}>AGREGAR CAMPO</Link>
                    </div>

                    <div className="container">
                        {this.props.usuario.length === 0 ? (
                            <div className="center">
                                <h2 id="font">No hay usuarios registrados</h2>
                            </div>
                        ) : (
                            <Table striped bordered hover >
                                <thead>
                                    <tr>
                                        <th>Tipo</th>
                                        <th>Nombre</th>
                                        <th>Acciones</th>
                                    </tr>
                                </thead>
                                <tbody id="font">
                                    {this.props.usuario.map(dato => (
                                        <tr key={dato.id}>
                                            <td>{dato.tipo}</td>
                                            <td>{dato.nombre}</td>
                                            <td>
                                                <Link style={{marginLeft:'14%'}} to={`/admin/editar-usuario/${dato.id}`}><i class="material-icons" style={{color:'#2a79b0 '}}>create</i></Link>
                                                <a href="#!" onClick={ () => this.eliminar(dato.id)}  style={{marginLeft:'14%'}}><i class="material-icons" style={{color:'#2a79b0 '}}>delete</i></a>
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </Table>
                        )}
                    </div>
                    
                </Fragment>
            );
        }
    }
}

Usuarios.protoType = {
    firestor : PropTypes.object.isRequired
}

export default compose(
    firestoreConnect(props => [ 
        {
            collection: 'usuario'
        }
    ]),
    connect(({ firestore: { ordered }}, props ) => ({
        usuario: ordered.usuario
    }))
)(Usuarios);