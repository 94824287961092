import React, {Fragment, Component}  from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { firestoreConnect } from 'react-redux-firebase';
import { Table } from 'react-bootstrap';
import PropTypes from 'prop-types';
import firebaseHelper from '../../../Helpers/Firebase';
import Header from '../../../Components/SideNav';


class Mensajes extends Component {
    
    constructor(props){
        super(props)
        this.state = {
            nombre: ''
        }
    }

    archivar = id => {
        console.log("archivar");

        const { firestore } = this.props;
        const { history } = this.props;
        const estado = {
            archivar: true
        }
        firebaseHelper.editar(id, "mensajes", firestore, estado, '/admin/mensajes', history);
    }


    render(){
        if (!this.props.mensajes) {
            return (
                <Fragment>
                    <Header />
                    <div className="container center-align" style={{marginTop:'20%', marginBottom:'20%'}}>
                        <div class="preloader-wrapper big active">
                            <div class="spinner-layer spinner-red darken-4-only">
                                <div class="circle-clipper left">
                                    <div class="circle"></div>
                                </div>
                                <div class="gap-patch">
                                    <div class="circle"></div>
                                </div>
                                <div class="circle-clipper right">
                                    <div class="circle"></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </Fragment>
            );
        } else {
            return (
                <Fragment>
                    <Header mensaje="Mensajes" />

                    <div className="container">
                        {this.props.mensajes.length === 0 ? (
                            <div className="center">
                                <h2 id="font">No hay mensajes registrados</h2>
                            </div>
                        ) : (
                            <Table striped bordered hover >
                                <thead>
                                    <tr>
                                        <th>Email</th>
                                        <th>Nombre</th>
                                        <th>Estado</th>
                                        <th>Acciones</th>
                                    </tr>
                                </thead>
                                <tbody id="font">
                                    {this.props.mensajes.map(dato => (
                                        <tr key={dato.id}>
                                            <td>{dato.email}</td>
                                            <td>{dato.nombre}</td>
                                            <td>
                                                {dato.estado ? (
                                                    <div style={{
                                                        width:"30px",
                                                        height:"30px",
                                                        background:"green"
                                                    }}/>
                                                ) : (
                                                    <div style={{
                                                        width:"30px",
                                                        height:"30px",
                                                        background:"red"
                                                    }}/>
                                                )}
                                            </td>
                                            <td>
                                                <button style={{border:"none", background:"transparent", cursor:"pointer"}} onClick={this.archivar}><i class="material-icons" style={{color:'#2a79b0 '}}>archive</i></button>
                                                <a style={{marginLeft:'14%'}} href={`/admin/ver-mensaje/${dato.id}`}><i class="material-icons" style={{color:'#2a79b0 '}}>remove_red_eye</i></a>
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </Table>
                        )}
                    </div>
                    
                </Fragment>
            );
        }
    }
}

Mensajes.protoType = {
    firestor : PropTypes.object.isRequired
}

export default compose(
    firestoreConnect(props => [ 
        {
            collection: 'mensajes',
            orderBy:'fecha',
            where: ["archivar", "==", false]
        }
    ]),
    connect(({ firestore: { ordered }}, props ) => ({
        mensajes: ordered.mensajes
    }))
)(Mensajes);