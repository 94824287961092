import React, {Fragment, Component}  from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { firestoreConnect } from 'react-redux-firebase';
import PropTypes from 'prop-types';
import firebaseHelper from '../../../Helpers/Firebase';
import Header from '../../../Components/SideNav';


class VerMensaje extends Component {
    
    constructor(props){
        super(props)
        this.state = {
            meses: [
                "Enero",
                "Febrero",
                "Marzo",
                "Abril",
                "Mayo",
                "Junio",
                "Julio",
                "Agosto",
                "Septiembre",
                "Octubre",
                "Noviembre",
                "Diciembre",
            ]
        }
    }


    atender = e => {
        e.preventDefault();
        const { firestore } = this.props;
        const { history } = this.props;
        const estado = {
            estado: true
        }
        firebaseHelper.editar(this.props.match.params.id, "mensajes", firestore, estado, '/admin/mensajes', history);
    }


    render(){
        if (!this.props.mensaje_seleccionado) {
            return (
                <Fragment>
                    <Header />
                    <div className="container center-align" style={{marginTop:'20%', marginBottom:'20%'}}>
                        <div class="preloader-wrapper big active">
                            <div class="spinner-layer spinner-red darken-4-only">
                                <div class="circle-clipper left">
                                    <div class="circle"></div>
                                </div>
                                <div class="gap-patch">
                                    <div class="circle"></div>
                                </div>
                                <div class="circle-clipper right">
                                    <div class="circle"></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </Fragment>
            );
        } else {
            return (
                <Fragment>
                    <Header mensaje="Mensajes" />

                    <div className="btn-outline-light container" style={{padding: '30px 0 30px 0'}}>
                        <a href={'/admin/mensajes'} id="font" class="btn waves-effect waves-light" style={{padding:'0 50px 0 50px', background:'#2a79b0 '}}>REGRESAR</a>
                        <button onClick={this.atender} className='btn' style={{marginLeft:"20px"}}>ATENDER</button>
                    </div>

                    <div className="container">
                        <h3>{this.props.mensaje_seleccionado.nombre}</h3>
                        <p>
                            {new Date(this.props.mensaje_seleccionado.fecha.seconds * 1000).getDate()} de {this.state.meses[new Date(this.props.mensaje_seleccionado.fecha.seconds * 1000).getMonth()]} del {new Date(this.props.mensaje_seleccionado.fecha.seconds * 1000).getFullYear()}
                        </p>
                        <p>Correo: <a target='_blanck' href={`mailto:${this.props.mensaje_seleccionado.email}`}>{this.props.mensaje_seleccionado.email}</a></p>
                        <p>Telefono: 
                            {!this.props.mensaje_seleccionado.telefono ? 
                                " sin información" : 
                                <a target='_blanck' href={`tel:${this.props.mensaje_seleccionado.telefono}`}>{this.props.mensaje_seleccionado.telefono}</a>
                            }
                        </p>
                        <p>WhatsApp: 
                            {!this.props.mensaje_seleccionado.telefono ? 
                                " sin información" : 
                                <a target='_blanck' href={`https://wa.me/${this.props.mensaje_seleccionado.telefono}`}>{this.props.mensaje_seleccionado.telefono}</a>
                            }
                        </p>
                        <p>{this.props.mensaje_seleccionado.mensaje}</p>
                    </div>
                    
                </Fragment>
            );
        }
    }
}

VerMensaje.protoType = {
    firestor : PropTypes.object.isRequired
}

export default compose(
    firestoreConnect(props => [ 
        {
            collection: 'mensajes',
            storeAs:"mensaje_seleccionado",
            doc: props.match.params.id
        }
    ]),
    connect(({ firestore: { ordered }}, props ) => ({
        mensaje_seleccionado: ordered.mensaje_seleccionado && ordered.mensaje_seleccionado[0]
    }))
)(VerMensaje);