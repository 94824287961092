import React, {Fragment}  from 'react';
import ReactDOM from 'react-dom';
import {BrowserRouter, Route} from 'react-router-dom';
import store from './store';
import { Provider } from 'react-redux';

import './index.css';


// *** administrador *** //
import login from './Views/administrador/Login';
//usuarios
import usuarios from './Views/administrador/Usuarios/Usuarios';
import nuevoUsuario from './Views/administrador/Usuarios/NuevoUsuario';
import editarUsuario from './Views/administrador/Usuarios/EditarUsuario';
//mensajes
import mensajes from './Views/administrador/Mensajes/Mensajes';
import verMensaje from './Views/administrador/Mensajes/VerMensaje';


const App = () =>(
    <Provider store={store}>

        <BrowserRouter>
            <Fragment>
                {/* administrador */}
                    <Route exact path = "/" component = {login} />
                    {/* usuario */}
                    <Route  path = "/admin/usuarios" component = {usuarios} />
                    <Route  path = "/admin/nuevo-usuario" component = {nuevoUsuario} />
                    <Route  path = "/admin/editar-usuario/:id" component = {editarUsuario} />
                    {/* fin - usuario */}
                    {/* usuario */}
                    <Route  path = "/admin/mensajes" component = {mensajes} />
                    <Route  path = "/admin/ver-mensaje/:id" component = {verMensaje} />
                    {/* fin - usuario */}
                {/* fin - administrador */}
            </Fragment>
        </BrowserRouter>
    </Provider>
);

ReactDOM.render(<App />, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
//serviceWorker.unregister();
